import { render, staticRenderFns } from "./addedit.vue?vue&type=template&id=93452ddc&scoped=true"
import script from "./addedit.vue?vue&type=script&lang=js"
export * from "./addedit.vue?vue&type=script&lang=js"
import style0 from "./addedit.vue?vue&type=style&index=0&id=93452ddc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93452ddc",
  null
  
)

export default component.exports